.episodes-page {
  background: #26B4E1 url(../../assets/images/Lab_BG_2000.png) no-repeat fixed;
  background-size: cover;
  display: flex;
  scroll-behavior: smooth;
  overflow: hidden;
  position: relative;

  .doors-open {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }

  .play-animation {
    .left-door {
      animation: door-animation-left 10s;
      display: block;
    }

    .right-door {
      animation: door-animation-right 10s;
      display: block;
    }
  }

  .left-door-monitor {
    display: none;
  }

  .right-door-monitor {
    display: none;
  }

  .left-door {
    max-height: 100vh;
    position: fixed;
    transition: transform 3s;
    transition-timing-function: ease;
    transform: translateX(-100%);
    display: none;
    width: 100vw;
    height: 100vh;
    z-index: 10;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @keyframes door-animation-left {
    0% {
      transform: translateX(-20%);
    }
    10% {
      transform: translateX(-20%);
    }
    60% {
      transform: translateX(-100%);
    }
  }

  .right-door {
    max-height: 100vh;
    position: fixed;
    transition: transform 3s;
    transition-timing-function: ease;
    display: none;
    transform: translateX(100%);
    width: 100vw;
    height: 100vh;
    z-index: 15;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @keyframes door-animation-right {
    0% {
      transform: translateX(18%);
    }
    10% {
      transform: translateX(18%);
    }
    70% {
      transform: translateX(100%);
    }
  }

  @media (min-width: 2600px) {
    .play-animation {
      .left-door {
        display: none;
      }

      .right-door {

        display: none;
      }
      .left-door-monitor {
        animation: door-animation-left 10s;
        display: block;
      }
      .right-door-monitor {
        animation: door-animation-right 10s;
        display: block;
      }
    }

    .left-door-monitor {
      max-height: 100vh;
      position: fixed;
      transition: transform 3s;
      transition-timing-function: ease;
      transform: translateX(-100%);
      display: none;
      width: 100vw;
      height: 100vh;
      z-index: 10;

      img {
        width: 100%;
        height: 100%;
      }
    }

    @keyframes door-animation-left {
      0% {
        transform: translateX(-30%);
      }
      10% {
        transform: translateX(-30%);
      }
      60% {
        transform: translateX(-100%);
      }
    }

    .right-door-monitor {
      max-height: 100vh;
      position: fixed;
      transition: transform 3s;
      transition-timing-function: ease;
      display: none;
      transform: translateX(100%);
      width: 100vw;
      height: 100vh;
      z-index: 15;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    @keyframes door-animation-right {
      0% {
        transform: translateX(27%);
      }
      10% {
        transform: translateX(27%);
      }
      50% {
        transform: translateX(100%);
      }
    }
  }

  .content {
    text-align: left;
    margin: 0 75px;
    color: white;
    background: rgba(black, .6);
    max-width: 1400px;
    @media (min-width: 1600px) {
      margin: auto;
    }

    .episodes-header {
      display: flex;

      button {
        display: flex;
        justify-content: center;
        margin: 30px auto;
        width: 25%;
        padding: 0;
      }

      .branding-switch {
        display: flex;
        align-items: center;

        p {
          width: 25%;
          margin: auto 0;
        }

        .switch {
          position: relative;
          display: inline-block;
          width: 10%;
          height: 34px;
        }

        .switch input {
          display: none;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          -webkit-transition: .4s;
          transition: .4s;
        }

        .slider:before {
          position: absolute;
          content: "";
          height: 26px;
          width: 26px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          -webkit-transition: .4s;
          transition: .4s;
        }

        input:checked + .slider {
          background-color: #2196F3;
        }

        input:focus + .slider {
          box-shadow: 0 0 1px #2196F3;
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }

        .slider-green {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #9BD770;
          -webkit-transition: .4s;
          transition: .4s;
          width: 60px;
        }

        .slider-green:before {
          position: absolute;
          content: "";
          height: 26px;
          width: 26px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          -webkit-transition: .4s;
          transition: .4s;
        }

        input:checked + .slider-green {
          background-color: #66B032;
        }

        input:focus + .slider-green {
          box-shadow: 0 0 1px #66B032;
        }

        input:checked + .slider-green:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }

        /* Rounded sliders */
        .slider-green.round {
          border-radius: 34px;
        }

        .slider-green.round:before {
          border-radius: 50%;
        }
      }

      p {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.6;
        width: 90%;
      }

      .dialog-box {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        z-index: 100;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 100vw;
        height: 100vh;

        .background-color {
          background-color: #5B5E60;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 30px;
          width: 80%;
        }
      }

      .confirm {
        font-size: 1.2rem;
        font-weight: 700;
        width: 50%;
        margin: auto;
        text-align: center;
      }

      .dialog-buttons {
        width: 80%;
        margin: auto;
        justify-content: space-between;
      }

      .buttons {
        display: flex;

        .delete {
          background-color: #BA0C2F;
          border: none;
          color: white;
          width: 15%;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
          text-shadow: 0 4px 4px #00000040;
          padding: .8em 2.8em;
          font-weight: 700;
          font-size: 1.1rem;
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            background-color: #f23945;
          }
        }

        .save {
          background-color: #69CAA4;
          border: none;
          color: white;
          text-align: center;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
          text-shadow: 0 4px 4px #00000040;
          padding: .8em 2.8em;
          font-weight: 700;
          font-size: 1.1rem;
          cursor: pointer;
          text-decoration: none;
          margin: auto;
          transition: 0.3s;

          &:hover {
            background-color: #64bb58;
          }
        }
      }

      .background {
        background: black;
        opacity: .8;
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }

      .download-episodes {
        width: 100%;
        display: flex;
        flex-direction: column;

        .tv {
          width: 65%;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .download-button {
          margin: 0 0 30px auto;
          padding: 0;
          width: 100%;
          height: 15%;
        }
      }

      .episode-title {
        display: flex;
        flex-direction: column;

        h2 {
          margin: 0.4em 0;
        }

        .updated-on {
          font-style: italic;
          margin-left: 15px;
          justify-content: flex-end;
          margin-bottom: 20px;
        }
      }

      .title-header {
        display: flex;
      }
    }

    .episode-display {
      .episode-display-header {
        display: flex;
        justify-content: space-between;
        margin-top: 2em;

        select {
          background-color: white;
          border: 2px solid #69CAA4;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
          font-weight: 700;
          width: 30%;
          padding-left: 10px;
          font-size: 1.2rem;
          cursor: pointer;
          font-family: Barlow, serif;
        }

        input {
          background-color: white;
          border: 2px solid #69CAA4;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
          font-weight: 700;
          padding-left: 10px;
          font-size: 1.2rem;
          height: 100%;
          font-family: Barlow, serif;
        }

        input#search {
          background-image: url('../../assets/images/Search Icon.svg');
          background-repeat: no-repeat;
          text-indent: 25px;
          background-position: left;
          background-position-x: 10px;
          background-size: 20px;
        }

        .search-container {
          display: flex;
          flex-direction: column;
          width: 30%;
        }

        .view-buttons {
          display: flex;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
          margin: 0 10px;

          .list-view {
            font-weight: 800;
            border-radius: 0 5px 5px 0;
            font-family: Barlow, serif;
          }

          .grid-view {
            border-right: 0;
            font-weight: 800;
            border-radius: 5px 0 0 5px;
            font-family: Barlow, serif;
          }

          button {
            font-weight: 600;
            font-size: 1.2rem;
            padding: .3em 1em;
            display: flex;
            margin: auto;
            background-color: white;
            color: #D4F0E5;
            border: 3px solid #69CAA4;
            cursor: pointer;
          }

          .selected {
            background-color: #69CAA4;
            color: white;
            font-family: Barlow, serif;
          }
        }
      }

      .video-layout {
        .no-episodes-div {
          display: flex;
          justify-content: center;
          margin: auto;
          text-align: center;

          p {
            font-size: 2rem;
          }
        }

        .TLZ-episodes.grid {
          margin-top: 2.5em;
          display: flex;
          justify-content: flex-start;
          flex-flow: row wrap;
          gap: 15px;

          .episode-content {
            flex: 0 0 32.5%;
            margin-bottom: 4em;

            @media (min-width: 1500px) {
              max-width: 24%;
            }

            .episode-thumbnail {
              cursor: pointer;

              img {
                width: 100%;
                height: 220px;
                object-fit: cover;
                object-position: center;
                @media (min-width: 1500px) {
                  height: 180px;
                }
              }

              &:hover {
                transform: translateY(-5px);
                transition: transform 0.2s ease 0s;
              }
            }

            .episode-text-and-download {
              .episode-text {
                margin-bottom: 2em;

                .download-layout {
                  .new-episode {
                    display: flex;
                    justify-content: space-between;

                    p {
                      margin-bottom: 0;
                    }

                    .grid-new-and-downloaded {
                      text-align: right;

                      .new {
                        color: #A6E76F;
                        font-weight: 600;
                        margin: .5em 0 0;
                      }

                      .downloaded {
                        color: #A6E76F;
                        font-weight: 600;
                      }
                    }
                  }
                }

                .list-view-download-and-new {
                  display: none;
                }

                .episode-number {
                  font-weight: 500;
                  margin: .5em 0;
                  font-size: 1.1rem;
                }

                .episode-title {
                  font-weight: 700;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  font-size: 1.4rem;
                  margin: .5em 0;
                }

                .episode-date {
                  font-weight: 500;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  margin: 0 0 .5em;
                }

                .description {
                  font-weight: 400;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  margin: .5em 0;
                  font-style: italic;
                }

                .episode-category {
                  margin: .5em 0 0;
                  font-weight: 500;
                  font-size: 1rem;
                }
              }

              .episode-download {
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
            }
          }

          .download-button {
            width: 100%;
          }
        }
      }

      .TLZ-episodes.list {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        margin: 2.5em auto auto;

        .episode-content {
          display: flex;
          margin-bottom: 2em;

          &:last-child {
            margin-bottom: 0;
          }

          .episode-thumbnail {
            margin-right: 1em;
            flex: 0 0 35%;
            cursor: pointer;

            img {
              width: 100%;
              height: 250px;
              object-fit: cover;
              object-position: center;
            }
          }

          .episode-text-and-download {
            display: flex;
            flex-direction: column;
            width: 100%;

            .episode-text {
              .download-layout {
                display: flex;
                justify-content: space-between;

                .new-episode {
                  font-size: 1.1rem;

                  .new {
                    display: none;
                  }

                  .downloaded {
                    display: none;
                  }
                }

                .list-view-download-and-new {
                  width: 40%;

                  .download-button {
                    width: 100%;
                  }

                  p {
                    margin: .6em;
                  }

                  .new {
                    text-align: right;
                    font-weight: 600;
                    color: #A6E76F;
                  }

                  .downloaded {
                    text-align: right;
                    font-weight: 600;
                    color: #A6E76F;
                  }
                }
              }

              .episode-number {
                font-weight: 600;
                margin: .5em 0;
              }

              .episode-title {
                font-weight: 700;
                margin: .5em 0;
                font-size: 1.6rem;
              }

              .episode-date {
                font-weight: 500;
                margin: 0 0 .5em;
              }

              .description {
                font-weight: 300;
                margin: 0 0 .5em;
                font-style: italic;
              }

              .episode-category {
                margin: 0;
                font-weight: 500;
                font-size: 1.1rem;
              }
            }

            .episode-download {
              .download-button {
                display: none;
              }
            }
          }
        }
      }

      .view-more {
        filter: drop-shadow(5px 5px 10px black);
        display: flex;
        margin: 0 auto 2em;
        background-color: transparent;
        border: none;
        cursor: pointer;

        &:hover {
          transform: translateY(-5px);
          transition: transform 0.2s ease 0s;
        }
      }

      .dialog-box {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        z-index: 100;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 100vw;

        .background-color {
          background-color: #0E465B;
          display: flex;
          justify-content: center;
          height: 100vh;
          width: 100vw;

          .exit-button {
            cursor: pointer;

            img {
              margin: 20px 20px 0;
            }
          }

          .episode-player {
            display: flex;
            flex-direction: column;
            width: 70%;
            margin-top: 20px;
            justify-content: center;

            .video-player {
              padding: 56.25% 0 0 0;
              position: relative;

              iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              }
            }

          }

          .episode-text {
            width: 30%;
            margin: 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
              max-width: 98%;
            }

            .episode-number {
              font-weight: 500;
              margin: 10px 0;
              font-size: 1.4rem;
            }

            .episode-title {
              font-weight: 700;
              margin: 10px 0;
              font-size: 2rem;
            }

            .episode-date {
              font-weight: 500;
              font-size: 1.2rem;
              margin: 0 0 10px;
            }

            .episode-category {
              font-weight: 500;
              font-size: 1.2rem;
              margin: 10px 0 0;
            }

            .episode-description {
              font-weight: 300;
              font-size: 1.2rem;
              font-style: italic;
              margin: 10px 0;
            }

            .download-button {
              width: 80%;
              margin: 20px 0;
            }
          }
        }

        .background {
          background: black;
          opacity: .5;
          height: 100vh;
          width: 100vw;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }
      }
    }

    .branding-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      h1 {
        font-weight: 700;
        font-style: italic;
        font-size: 2.5rem;
      }

      p {
        text-align: center;
        max-width: 60%;
        font-weight: 400;
        font-size: 21px;
        line-height: 25px;
      }
    }

    .branding-choices {
      display: flex;

      button {
        padding: 0.8em 0.5em;
        background-color: #69CAA4;
        justify-content: center;
        display: flex;
        width: 60%;
        margin: 5% auto;
        text-decoration: none;
        color: white;
        font-weight: 700;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.2509803922);
        font-size: 1.2rem;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        transition: 0.3s;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: #64bb58;
        }
      }

      p {
        align-items: center;
        display: flex;
        margin: auto 20px auto 5px;
      }
    }

    .nationally-branded {
      width: 50%;

      p {
        margin: 10px 15px;
      }

      h2 {
        font-size: 2rem;
        text-align: center;
      }

      .image-div {
        display: flex;

        img {
          width: 95%;
          height: 95%;
          margin: auto;
        }
      }
    }

    .station-branded {
      width: 50%;

      p {
        margin: 10px 0;
      }

      h2 {
        font-size: 2rem;
        text-align: center;
      }

      .image-div {
        img {
          width: 97%;
          height: 95%;
          margin: auto;
        }
      }


    }

    .tutorial {
      .tutorial-video {
        .iframe {
          padding: 56.25% 0 0 0;
          position: relative;

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }

      .tutorial-text {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        p {
          font-weight: 600;
          font-size: 1.3rem;
          margin-top: 0;
        }
      }
    }

    .tutorial-download {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .billy {
        height: 14em;
        margin: 1em 0;
        display: flex;
        align-items: end;
        align-self: end;
      }

      .penelope {
        height: 12.5em;
        margin: 1em 0 1em 2em;
        display: flex;
        align-items: end;
        align-self: end;
      }

      .download-button-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40%;
        justify-content: center;
        height: 60%;
        margin: auto;

        .download-button {
          width: 100%;
          text-decoration: none;
        }

        p {
          font-weight: 600;
          font-size: 1.3rem;
          text-align: center;
          margin-bottom: 0;
        }
      }
    }
  }

  .home-button {
    margin-bottom: 2em;

    a {
      padding: .8em .3em;
      background-color: #69CAA4;
      justify-content: center;
      display: flex;
      width: 20%;
      margin: 0 auto;
      text-decoration: none;
      color: white;
      font-weight: 700;
      text-shadow: 0 4px 4px #00000040;
      font-size: 1.2rem;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      transition: 0.3s;

      &:hover {
        background-color: #64bb58;
      }
    }
  }

  .padding {
    padding: 0 40px;
  }

  header {
    img {
      width: 100%;
    }
  }
}