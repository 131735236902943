.login {
  background: #00a5fe url(../../assets/images/Lab_BG_2000.png) no-repeat center bottom;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  .select-search-select {
    list-style: none;
  }

  .select-search-options {
    padding: 0;
  }

  .select-search-input {
    height: 40px;
    border-radius: 3px;
    border: none;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    font-size: 1.1rem;
    padding-left: 10px;
    font-family: Barlow;
    font-weight: 500;
  }

  .doors-closing {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;

    .left-door {
      max-height: 100vh;
      position: absolute;
      transition: transform 3s;
      transition-timing-function: ease;
      transform: translateX(-100%);
      display: none;
      width: auto;
      height: 100vh;
      z-index: 10;
    }

    @keyframes door-close-animation-left {
      0% {
        transform: translateX(-100%)
      }
      45% {
        transform: translateX(-.05%)
      }
      55% {
        transform: translateX(-.05%)
      }
      100% {
        transform: translateX(-.05%)
      }
    }

    &.animated {
      .left-door {
        animation: door-close-animation-left 10s;
        display: block;
      }
    }

    .right-door {
      max-height: 100vh;
      position: absolute;
      transition: transform 3s;
      transition-timing-function: ease;
      display: none;
      transform: translateX(100%);
      width: auto;
      height: 100vh;
      z-index: 15;
    }

    &.animated {
      .right-door {
        animation: door-close-animation-right 10s;
        display: block;
      }
    }

    @keyframes door-close-animation-right {
      0% {
        transform: translateX(100%)
      }
      45% {
        transform: translateX(18%)
      }
      55% {
        transform: translateX(18%)
      }
      100% {
        transform: translateX(18%)
      }
    }
  }


  .container {
    display: flex;
  }

  .tlz-logo {
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: baseline;

    img {
      width: 100%;
      min-width: 150px;
    }
  }

  form {
    color: white;
    background: rgba(black, .7);
    height: 110%;
    width: 70%;
    padding: 0 2%;
    margin: 0 40px;
    max-height: 100vh;
  }

  h2 {
    font-weight: 900;
    font-size: 1.6rem;
  }

  .user-interaction {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3em;
  }

  .select-station {
    width: 49%;
    display: flex;
    align-items: center;

    label {
      font-size: 1.4rem;
      font-weight: 900;
      margin-right: 20px;
    }

    select {
      width: 70%;
      height: 40px;
      background-color: white;
      border: none;
      border-radius: 3px;
      font-size: 1.1rem;
      font-weight: 300;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      padding-left: 10px;
    }
  }

  .enter-email {
    width: 49%;
    text-align: right;
    display: flex;
    align-items: center;

    label {
      font-size: 1.4rem;
      font-weight: 900;
      margin-right: 20px;

    }

    input {
      width: 70%;
      height: 40px;
      border-radius: 3px;
      border: none;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      font-size: 1.1rem;
      padding-left: 10px;
      @media (prefers-color-scheme: dark) {
        background-color: black;
        color: lightgray;
      }
    }
  }

  .description {
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 20px;
    line-height: 1.5;

    p {
      margin-bottom: 0;
    }
  }

  .continue-button {
    display: flex;
    justify-content: center;

    button {
      font-weight: 600;
      font-size: 1.4rem;
      padding: .7em 1em;
      display: flex;
      max-height: 90%;
      border: 2px solid #69CAA4;
      background-color: #69CAA4;
      color: white;
      cursor: pointer;
      text-shadow: 0 4px 4px #00000040;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      transition: 0.3s;
      &:hover{
        background-color: #64bb58;
        border: #64bb58 2px solid;
      }
      &:disabled{
        background-color: #5B5E60;
        border: 2px solid #5B5E60;
        cursor: default;
      }
    }
  }

  .billy-and-penelope {
    display: flex;
    justify-content: space-around;
    align-items: end;
    margin-bottom: 3em;
  }
  .billy {
    max-height: 55vh;
  }

  .penelope {
    max-height: 45vh;
  }

  @media (max-width: 850px) {
    .billy{
      max-height: 38vh;
    }
    .penelope{
      max-height: 25vh;
    }
  }
}