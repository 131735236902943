html {
  scroll-behavior: smooth;
}

.home {
  background: #26B4E1 url(../../assets/images/Lab_BG_2000.png) no-repeat fixed;
  background-size: cover;
  display: flex;
  scroll-behavior: smooth;

  .content {
    text-align: left;
    margin: 0 75px;
    color: white;
    background: rgba(black, .6);
    max-width: 1400px;
    @media (min-width: 1600px) {
      margin: auto;
    }
  }

  h2 {
    margin-top: 0;
    margin-left: 0;
  }

  header {
    img {
      width: 100%;
    }
  }

  .padding {
    padding: 0 40px;
  }

  .about-show {
    margin-top: 3em;

    h2 {
      font-size: 5rem;
    }
  }

  .about-show-content {
    display: flex;

    p {
      margin-top: 0;
      margin-right: 40px;
      display: flex;
      flex-direction: column;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.6;
    }
  }

  .about-show-text {
    flex: 1 1;
    p:first-child {
      font-weight: 600;
    }

  }

  .iframe-mobile {
    display: none;
  }

  .iframe {
    display: flex;
    margin: auto;
    flex-direction: column;
    flex: 2 2;

    iframe{
      width: 100%;
      aspect-ratio: 16/9;

    }

    p {
      font-size: 1.1rem;
      font-style: italic;
    }
  }

  .about-billy-and-penelope {
    display: none;
  }

  .billy-and-penelope {
    display: flex;
    flex-direction: column;
  }

  .billy-penelope-about-content {
    display: flex;

    p {
      font-weight: 400;
      margin-top: 0;
      font-size: 1.4rem;
      line-height: 1.6;
    }
  }

  .image {
    display: flex;
    width: 50%;
    margin: 0 auto;

    img {
      transform: scaleX(-1);
      width: 100%;
    }
  }

  .hr {
    display: none;
  }

  .italics {
    font-style: italic;
  }

  .episodes-header {
    display: none;
    flex-direction: column;

    p {
      margin-top: 0;
      display: flex;
      flex-direction: column;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.6;
    }

    .bold {
      font-weight: 600;
    }

    a {
      padding: 1em .5em;
      background-color: #69CAA4;
      justify-content: center;
      display: flex;
      width: 30%;
      margin: 2em auto 3em;
      text-decoration: none;
      color: white;
      font-weight: 700;
      text-shadow: 0 4px 4px #00000040;
      font-size: 1.7rem;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }

    a:hover {
      background-color: #03a7fc;
    }
  }

  .desktop-view {
    display: flex;
    justify-content: space-between;
    gap: 100px;
  }

  .episodes-header-desktop {
    p {
      margin-top: 0;
      display: flex;
      flex-direction: column;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.6;
    }

    .bold {
      font-weight: 600;
    }

    a {
      padding: 1em .5em;
      background-color: #69CAA4;
      justify-content: center;
      display: flex;
      width: 50%;
      margin: 0 auto;
      text-decoration: none;
      color: white;
      font-weight: 700;
      text-shadow: 0 4px 4px #00000040;
      font-size: 1.6rem;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      transition: 0.3s;

      &:hover {
        background-color: #64bb58;
      }
    }

  }

  .updated-on {
    font-style: italic;
    margin-left: 15px;
  }

  .about-corps {
    a {
      padding: 1em .5em;
      background-color: #69CAA4;
      justify-content: center;
      display: flex;
      width: 30%;
      margin: 2em auto;
      text-decoration: none;
      color: white;
      font-weight: 700;
      text-shadow: 0 4px 4px #00000040;
      font-size: 1.7rem;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      transition: 0.3s;

      &:hover {
        background-color: #64bb58;
      }
    }
  }

  .about-corps-text {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: 80%;
    text-align: center;
    margin: 2em auto 3em;

    p {
      font-weight: 400;
      font-size: 1.4rem;
      margin-bottom: 0;
      line-height: 1.6;
    }
  }

  .about-corps-photo {
    width: 80%;
    margin: auto;

    img {
      width: 100%;
    }
  }

  .credits {
    margin-bottom: 2em;

    h2 {
      text-align: center;
    }
  }

  .credits-arrow {
    margin: 0 auto;
    max-width: 6%;
    cursor: pointer;
    user-select: none;
    filter: drop-shadow(5px 5px 10px black);

    &:hover {
      transform: translateY(-5px);
      transition: transform 0.2s ease 0s;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .credits-content {
    display: flex;
    justify-content: space-between;
  }

  .credits-content-mobile {
    display: none;
  }

  .credit-names {
    background-color: #26B4E1;
    width: 80%;
    display: flex;
    flex-flow: wrap;
    justify-content: space-evenly;
    padding: 40px 0;
  }

  .credits-text {
    text-align: center;
    flex: 0 0 20%;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;

    p {
      margin: 0;
    }
  }

  .team {
    font-weight: 500;
  }

  @media (min-width: 500px) and (max-width: 850px) {
    .content {
      margin: 0 50px;
    }
    .padding {
      padding: 0 30px;
    }
    .about-show {
      h2 {
        font-size: 2.8rem;
      }
    }
    h2 {
      font-size: 2.5rem;
    }
    .about-show-content {
      flex-direction: column;

      p {
        font-size: 1.2rem;
      }
    }
    .billy-and-penelope {
      width: 100%;
    }
    .about-billy-and-penelope {
      display: flex;
    }
    .billy-penelope-about-content {
      p {
        font-size: 1.2rem;
      }
    }

    .iframe {
      margin: auto;
      display: flex;
      iframe{
        width: 100%;
        aspect-ratio: 16/9;
      }
    }

    .iframe-mobile {
      display: none
    }

    .desktop-view {
      display: none;
    }

    .image {
      width: 50%;
    }
    .hr {
      display: flex;
    }
    .episodes-header {
      display: flex;

      p {
        font-size: 1.2rem;
      }

      a {
        font-size: 1.4rem;
        width: 40%;
        margin: 1em auto auto;
      }
    }
    .credits-text {
      p {
        font-size: .8rem;
      }
    }
    .about-corps {
      a {
        font-size: 1.4rem;
        width: 40%;
        margin: 1em auto 2em;
      }
    }
  }
  @media (max-width: 500px) {
    .content {
      margin: 0 15px;
    }
    .padding {
      padding: 0 15px;
    }
    .about-show {
      margin-top: 2em;
      h2 {
        font-size: 1.5rem;
      }
    }
    h2 {
      font-size: 1.5rem;
    }
    .desktop-view {
      display: none;
    }
    .about-show-content {
      flex-direction: column;

      p {
        font-size: 1rem;
        margin-right: 0;
      }
    }
    .iframe-mobile {
      display: flex;
      flex-direction: column;
      p{
        font-size: .8rem;
        font-style: italic;
      }
    }
    .iframe {
      display: none;
    }
    .billy-and-penelope {
      width: 100%;
    }
    .about-billy-and-penelope {
      display: flex;
      flex-direction: column-reverse;
    }
    .billy-penelope-about-content {
      p {
        font-size: 1rem;
      }
    }
    .image {
      width: 50%;
    }
    .hr {
      display: flex;
    }
    .episodes-header {
      display: flex;

      a {
        font-size: 1rem;
        width: 50%;
        margin: 1em auto 0;
      }

      p {
        font-size: 1rem;
      }
    }
    .credits-content {
      display: none;
    }
    .credits-content-mobile {
      display: flex;
    }
    .credits-text {
      margin: 3px;
      flex: 0 0 40%;

      p {
        font-size: .9rem;
      }
    }
    .credit-names {
      padding: 20px 0;
    }
    .about-corps-text {
      margin: 1em auto;

      p {
        font-size: 1rem;
        margin: 0;
      }
    }
    .about-corps {
      a {
        margin: 1em auto;
        font-size: 1rem;
        text-align: center;
      }
    }
  }
}