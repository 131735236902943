.admin {
  background: #26B4E1 url(../../assets/images/Lab_BG_2000.png) no-repeat fixed;
  background-size: cover;
  height: 100vh;
  width: 100vw;

  header {
    display: flex;
    background-color: #A6E76F;

    img {
      margin: auto 20px 10px 10px;
      max-width: 7%;
    }

    p {
      color: #5B5E60;
      font-weight: 700;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
    }
  }

  .admin-content {
    color: white;
    background: rgba(black, .6);
    max-width: 90%;
    height: 80vh;
    margin: auto;
    display: flex;
  }

  .edit-episodes {
    width: 50%;
  }

  form {
    padding: .5em 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 95%;
  }

  .label-and-edit {
    display: flex;
    justify-content: space-between;

    label {
      font-size: 1.4rem;
      font-weight: 600;
    }

    input {
      width: 70%;
      border-radius: 5px;
      border: none;
    }

    textarea {
      width: 70%;
      border-radius: 5px;
      border: none;
      height: 15vh;
      resize: none;
    }

    select {
      width: 70.75%;
      border-radius: 5px;
      border: none;
    }
  }

  .add-episode {
    display: flex;
    justify-content: space-between;

    h3 {
      margin: 0;
      font-size: 1.4rem;
      font-weight: 900;
    }
  }

  .episode-select {
    width: 50%;
    display: flex;
    justify-content: flex-end;

    label {
      font-size: 1.1rem;
      display: flex;
      align-items: center;
      font-weight: 500;
      margin-right: 10px;
    }

    select {
      background-color: white;
      border: none;
      width: 45%;
      height: 30px;
    }
  }

  .episode-number {
    display: flex;
    justify-content: space-between;
    height: 29px;
  }

  .label-and-input {
    width: 67%;
    margin: 0;

    input {
      width: 40%;
      margin-right: 3%;
    }
  }

  .episode-status {
    display: flex;
    width: 60%;
    margin: 0;
    justify-content: center;
    p{
      font-size: 0.75rem;
      margin: 0 0 5px;
      text-align: center;
    }

    img {
      width: 100%;
    }
  }

  .dialog-box {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 100;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100vw;
    height: 100vh;
  }

  .background-color {
    background-color: #5B5E60;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
  }

  .confirm {
    font-size: 1.2rem;
    font-weight: 700;
  }

  .info {
    width: 80%;
    margin: auto;
  }

  .episode-info {
    display: flex;
    justify-content: space-between;
    margin: 2em 0;

    label {
      font-size: 1.2rem;
      font-weight: 700;
    }

    p {
      margin: 0 10px;
      background-color: white;
      color: black;
      padding: 5px;
      border-radius: 4px;
      width: 70%;
      font-size: 1rem;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }
  }

  .dialog-buttons {
    width: 80%;
    margin: auto;
  }

  .buttons {
    display: flex;
    justify-content: space-evenly;
  }

  .delete {
    background-color: #BA0C2F;
    border: none;
    color: white;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    text-shadow: 0 4px 4px #00000040;
    padding: .8em 2.8em;
    font-weight: 700;
    font-size: 1.1rem;
    margin-right: 10px;
    cursor: pointer;
  }

  .save {
    background-color: #69CAA4;
    border: none;
    color: white;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    text-shadow: 0 4px 4px #00000040;
    padding: .8em 2.8em;
    font-weight: 700;
    font-size: 1.1rem;
    cursor: pointer;
    &:disabled{
      background-color: #5B5E60;
    }
  }

  .background {
    background: black;
    opacity: .8;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  hr {
    margin: auto;
    border: 3px solid white;
    border-radius: 10px;
    height: 90%;
  }

  .statistics {
    width: 50%;
    padding: .5em 2em;

    label {
      font-weight: 600;
      font-size: 1rem;
    }

    h3 {
      margin: 10px 0;
      font-size: 1.4rem;
      font-weight: 900;
    }
  }

  .total {
    display: flex;
    margin-bottom: 1em;

  }

  .downloads {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;

    label {
      display: flex;
      align-items: flex-end;
    }

    p {
      margin: 0 0 0 5px;
      background-color: white;
      color: black;
      padding: 5px 10px;
    }
  }

  .station-count {
    display: flex;
    justify-content: space-between;

    label {
      display: flex;
      align-items: flex-end;
    }

    p {
      margin: 0 0 0 5px;
      background-color: white;
      color: black;
      padding: 5px 10px;
    }
  }

  .stations {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    ul {
      padding: 0;
      margin: 0;
    }
  }

  .station-map {
    background-color: white;
    width: 70%;
    height: 28vh;
  }

  .station-list {
    background-color: white;
    width: 28%;
    height: 28vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .map-of-stations {
    margin: 10px 0;

    p {
      margin: 0;
      color: black;
      text-align: center;
    }
  }

  .station-name {
    font-weight: 700;
    font-size: 1.2rem;
  }

  .most {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .download-eps {
    width: 40%;

    label {
      justify-content: center;
      width: 100%;
      display: flex;
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      margin: 0;
    }

    li {
      color: black;
    }
  }

  .most-downloaded {
    background-color: white;
    margin: 10px auto 0;
    height: 30vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .most-downloaded-list {
    list-style: none;
    font-weight: 700;
    font-size: 1.2rem;
  }

  .downloaded-list {
    margin: 10px auto;
    text-align: center;
  }

  .episode-download {
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
  }

  .popular {
    width: 55%;

    label {
      justify-content: center;
      width: 100%;
      display: flex;
    }
  }

  .category-chart {
    background-color: white;
    height: 30vh;
    margin: 10px auto 0;
    color: black;

    .recharts-default-legend {
      font-size: .8rem;
    }
  }
}