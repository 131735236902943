.App {
  font-family: Barlow, serif;
  button{
    font-family: Barlow, serif;
  }
}

.download-button {
  text-shadow: 0 4px 4px #00000040;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  background-color: #69CAA4;
  border: none;
  color: white;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: 0.3s;

  &:hover {
    background-color: #64bb58;
  }

  img {
    height: 2em;
    margin-right: .2em;
    transform: translateY(.1em);
    display: flex;
    align-items: center;
  }
}

.logos {
  display: flex;
  justify-content: space-evenly;
  padding: 1.5em 30px;
  background-color: white;
  align-items: center;

  img {
    max-width: 10em;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  @media (max-width: 500px) {
    img {
      max-width: 8em;
    }
  }
}

.copyright {
  p {
    font-size: 1.3rem;
    font-weight: 700;
    text-align: right;
    margin: 2em 1em;
  }

  @media (max-width: 500px) {
    p {
      font-size: 1rem;
    }
  }
}

h2 {
  font-size: 3rem;
  font-weight: 600;
  color: white;
  margin: 0.4em auto;
}

hr {
  border: 4px solid #69CAA4;
  border-radius: 5px;
  margin: 3em 0;
  @media (max-width: 500px) {
    margin: 2em 0;
  }
}